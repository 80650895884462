import { TimeRanges, CustomTimeRangeTypes, TimeFormat } from '@colven/common-domain-lib/lib'
import i18n from '@/i18n'

export default {
  showClose: true,
  filters: [],
  selects: [],
  disableGetReportButton: true,
  showDateAndTimeSelector: true,
  showTimeFormatSelector: true,
  selectedTimeFormat: {
    value: TimeFormat.HH_MM_SS.id,
    text: TimeFormat.HH_MM_SS.name
  },
  selectedDateAndTimeRange: TimeRanges.LAST_HOUR,
  selectedDateAndTimeRangeCustomType: CustomTimeRangeTypes.DATE,
  sinceDate: null,
  sinceTime: null,
  toDate: null,
  toTime: null,
  customDateTimeValidForm: false,
  autoReport: false,
  autoReportMinutes: 10,
  reportName: null,
  numericInputs: [],
  // visualizar el switch y filtro grupos -> equipos
  showGroupEquipmentFilter: false,
  // switch para cambiar los filtros
  filterSwitch: {
    id: 'groupEquipmentFilter',
    name: i18n.t('selector.filter'),
    show: false,
    value: false,
    textTrue: i18n.t('things'),
    textFalse: i18n.t('selector.composed')
  },
  // filtro de grupos -> equipos
  groupEquipmentFilter: {
    id: 'groupEquipmentFilter',
    name: i18n.t('things'),
    show: false,
    disabled: false,
    showDialog: false,
    singleSelect: false,
    data: [],
    selectedData: [],
    selectAction: undefined
  },
  // switches configurables
  switches: [],
  rangeData: {
    propertyName: '',
    minLimit: '',
    maxLimit: '',
    rules: {},
    showRangeSection: false,
    showRangeData: false,
    greaterThan: '',
    lessThan: '',
    limitRequired: false
  }
}
