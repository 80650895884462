import moment from 'moment'
import i18n from '@/i18n'
import ListTreeViewComponent from '@/components/commons/list/tree-view/ListTreeViewComponent.vue'
import SelectorDialog from '@/components/commons/selector/dialog/SelectorDialog.vue'
import { TimeRanges, CustomTimeRangeTypes, TimeFormat } from '@colven/common-domain-lib/lib'
import { getIds } from '@/tools/functions'
import deepcopy from 'deepcopy'
import selectorService from '@/business/selectorService'
import autoReportService from '@/business/autoReportService'
import AutocompleteComponent from '@/components/commons/autocomplete/AutocompleteComponent.vue'
import { DialogType } from '@/constants/constants'
import { mapActions } from 'vuex'

export default {
  name: 'SelectorComponent',
  model: {
    prop: 'model',
    event: 'change'
  },
  /**
   * MODELO
   *
   * showClose: boolean, para mostrar/ocultar el botón de cerrar.
   * filters: arreglo de filtros (cada uno abre un diálogo para seleccionar).
   *  --> Ejemplo:
   *      filters: [
   *        {
   *          id: <identificador>,
   *          name: <nombre de la entidad que se quiere filtrar - ej: Camiones>,
   *          show: <boolean para mostrarlo/ocultarlo>,
   *          disabled: <boolean para deshabilitarlo>,
   *          data: <array de items que pueden ser seleccionados (es la totalidad del universo de datos, incluidos los ya seleccionados)>,
   *          selectedData: <array de items que ya fueron seleccionados>
   *          selectorDialogItems: <array de los items seleccionados en el diálogo>
   *        }
   *      ]
   * selects: arreglo de selectores del tipo dropdown (múltiples o simples).
   *  --> Ejemplo:
   *      selects: [
   *        {
   *          id: <identificador>,
   *          name: <nombre de lo que se quiere seleccionar - ej: Actividad>,
   *          show: <boolean para mostrarlo/ocultarlo>,
   *          disabled: <boolean para deshabilitarlo>,
   *          data: <array de items que pueden ser seleccionados>,
   *          selectedData: <array de items, o item, seleccionados>
   *          multiple: <boolean para habilitar la selección múltiple>
   *        }
   *      ]
   * hideAutoReport: booleano para mostrar/ocultar el autoreporte.
   * autoReport: booleano para activar/desactivar el autoreporte,
   * autoReportMinutes: minutos del autoreporte,
   * disableGetReportButton: boolean, para deshabilitar/habilitar el botón para generar el reporte.
   * showTimeFormatSelector: boolean, para mostrar/ocultar el selector del formato de tiempo.
   * showDateAndTimeSelector: boolean, para mostrar/ocultar el selector de rango de tiempo.
   * selectedDateAndTimeRange: el tipo de rango de tiempo seleccionado (constante TimeRanges).
   * selectedDateAndTimeRangeCustomType: el tipo de rango personalizado seleccionado (constante CustomTimeRangeTypes).
   * selectedTimeFormat: el formato de tiempo seleccionado (constante TimeFormat).
   * sinceDate: fecha en formato YYYY-MM-DD (null si no se inicializa).
   * sinceTime: hora en formato hh:mm (null si no se inicializa).
   * toDate: fecha en formato YYYY-MM-DD (null si no se inicializa con una fecha particular).
   * toTime: hora en formato hh:mm (null si no se inicializa).
   * customDateTimeValidForm: booleano que indica la validez del formulario de rangos de fecha y hora personalizado.
   *
   * EVENTOS
   *
   * change: el evento que se lanza cuando se modifica el modelo.
   * get-report: se lanza cuando se hace click en botón para obtener el reporte.
   * close: se lanza cuando se hace click en el botón para cerrar el selector.
   */
  props: {
    model: {
      type: Object,
      required: true
    },
    // para habilitar el dialog para ingresar el nombre del auto-reporte, y luego guardarlo en la base de datos indexada
    autoReportNameEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    // clave del sector (para los reportes de Ledesma que están relacionados a un sector en particular)
    sectorKey: {
      type: String,
      required: false,
      default: null
    },
    // mostrar un dialog de advertencia cuando se hace click en el botón para generar el reporte
    showWarning: {
      type: Boolean,
      required: false,
      default: false
    },
    // texto del warning
    warningText: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    SelectorDialog,
    ListTreeViewComponent,
    AutocompleteComponent
  },
  data() {
    return {
      autoReportDisableDate: false,
      dateAndTimeRanges: [
        // ultima hora, ultimas 6 horas, hoy, ayer, ultima semana
        {
          id: TimeRanges.LAST_HOUR,
          text: i18n.t('selector.dateAndTime.lastHour'),
          custom: false
        },
        {
          id: TimeRanges.LAST_SIX_HOUR,
          text: i18n.t('selector.dateAndTime.lastSixHours'),
          custom: false
        },
        {
          id: TimeRanges.TODAY,
          text: i18n.t('selector.dateAndTime.today'),
          custom: false
        },
        {
          id: TimeRanges.YESTERDAY,
          text: i18n.t('selector.dateAndTime.yesterday'),
          custom: false
        },
        {
          id: TimeRanges.LAST_WEEK,
          text: i18n.t('selector.dateAndTime.lastWeek'),
          custom: false
        },
        {
          id: TimeRanges.ENTERPRISE,
          text: '',
          custom: false
        },
        {
          id: TimeRanges.ENTERPRISE_PREVIOUS,
          text: '',
          custom: false
        },
        {
          id: TimeRanges.CUSTOM,
          text: i18n.t('selector.dateAndTime.custom'),
          custom: true
        }
      ],
      dateAndTimeCustomRangeTypes: [
        {
          text: i18n.t('selector.dateAndTime.customTypes.dateAndTime'),
          value: CustomTimeRangeTypes.DATE_AND_TIME
        },
        {
          text: i18n.t('selector.dateAndTime.customTypes.date'),
          value: CustomTimeRangeTypes.DATE
        }
      ],
      dateAndTimeFormat: [
        {
          value: TimeFormat.HH_MM_SS.id,
          text: TimeFormat.HH_MM_SS.name
        },
        {
          value: TimeFormat.DD_HH_MM_SS.id,
          text: TimeFormat.DD_HH_MM_SS.name
        }
      ],
      showTimeFormat: false,
      showDateAndTime: false,
      showAutoReport: false,
      menuSinceDate: false,
      menuToDate: false,
      menuSinceTime: false,
      menuToTime: false,
      rules: {
        required: value => !!value || `${i18n.t('required')}`,
        twoDatesValidation: (firstDate, secondDate, singleDaySelect) => singleDaySelect || firstDate.getTime() >= secondDate.getTime() || `${i18n.t('selector.dateAndTime.dateError')}`,
        twoTimesValidation: (firstTime, secondTime, firstDate, secondDate, selectedType) => (selectedType === CustomTimeRangeTypes.DATE) ||
          (selectedType === CustomTimeRangeTypes.DATE_AND_TIME && firstDate.getTime() < secondDate.getTime()) ||
          (selectedType === CustomTimeRangeTypes.DATE_AND_TIME && firstDate.getTime() === secondDate.getTime() && firstTime && secondTime && moment(firstTime, 'hh:mm').isSameOrBefore(moment(secondTime, 'hh:mm'), 'minute')) ||
          (selectedType === CustomTimeRangeTypes.TIME && firstTime && secondTime && moment(firstTime, 'hh:mm').isSameOrBefore(moment(secondTime, 'hh:mm'), 'minute')) ||
          `${i18n.t('selector.dateAndTime.timeError')}`
      },
      timeRanges: TimeRanges,
      customDateAndTimeRangeType: CustomTimeRangeTypes,
      timeFormat: TimeFormat,
      selectorDialogModel: {
        normalizer: (node) => {
          return {
            id: node.id,
            label: node.name,
            children: node.children
          }
        },
        data: [],
        selected: null,
        show: false,
        title: i18n.t('selector.selector'),
        singleSelect: false,
        selectAll: false,
        saveDisabled: false,
      },
      currentFilter: undefined,
      // model para mostrar/ocultar el dialog
      showAutoreportNameDialog: false,
      // model para la validez del formulario del nombre para el autoreporte
      autoReportNameFormValid: false,
      // mensajes de error para el campo del nombre del autoreporte
      reportNameErrors: [],
      selectedCount: 0
    }
  },
  computed: {
    autoReportSwitchLabel() {
      if (this.model.autoReport) {
        return this.$t('selector.autoReport.enabled')
      } else {
        return this.$t('selector.autoReport.disabled')
      }
    },
    disableAutoReport() {
      if (this.model.forceReportSwitch) return false;
      return !this.model.autoReport || this.model.selectedDateAndTimeRange === TimeRanges.YESTERDAY || this.model.selectedDateAndTimeRange === TimeRanges.CUSTOM
    },
    disableAutoReportSwitch() {
      if (this.model.forceReportSwitch) return false;
      return this.model.selectedDateAndTimeRange === TimeRanges.YESTERDAY || this.model.selectedDateAndTimeRange === TimeRanges.CUSTOM
    },
    formattedSinceDate() {
      return this.formatDate(this.model.sinceDate)
    },
    formattedToDate() {
      return this.formatDate(this.model.toDate)
    }
  },
  async mounted() {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale

    // rango de tiempo "jornada diaria" de la empresa
    if (JSON.parse(localStorage.getItem('enterpriseTimeSpan'))) {
      this.dateAndTimeRanges.find(x => x.id === TimeRanges.ENTERPRISE).text = i18n.t('selector.dateAndTime.dailyShift')
      this.dateAndTimeRanges.find(x => x.id === TimeRanges.ENTERPRISE_PREVIOUS).text = i18n.t('selector.dateAndTime.previousShift')
    } else {
      const enterpriseTimeSpan = await selectorService.getEnterpriseCustomTimeSpan()
      if (enterpriseTimeSpan) {
        localStorage.setItem('enterpriseTimeSpan', JSON.stringify(enterpriseTimeSpan))
        this.dateAndTimeRanges.find(x => x.id === TimeRanges.ENTERPRISE).text = i18n.t('selector.dateAndTime.dailyShift')
        this.dateAndTimeRanges.find(x => x.id === TimeRanges.ENTERPRISE_PREVIOUS).text = i18n.t('selector.dateAndTime.previousShift')
      } else {
        this.dateAndTimeRanges.splice(-2, 1)
      }
    }

    // cargar datos para el selector de grupos -> equipos
    if (this.model.showGroupEquipmentFilter && this.model.groupEquipmentFilter != null) {
      const data = await selectorService.getEnterpriseGroupEquipmentTreeSelector(this.sectorKey)
      this.model.groupEquipmentFilter.data.cleanAndUpdate(data)
    }
  },
  methods: {
    // actions del dialog global
    ...mapActions('dialog', {
      openDialog: 'openDialog'
    }),
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    arrowIcon(show) {
      if (show) {
        return 'arrow_drop_up'
      } else {
        return 'arrow_drop_down'
      }
    },
    getIds(data, singleSelect) {
      if (singleSelect) {
        return data.length === 1 ? data[0].id : null
      } else {
        const result = []
        getIds(data, result)
        return result
      }
    },
    /**
     * Se carga el modelo de datos del diálogo de selección
     * @param {*} filterData
     */
    selectEnitites(filterData) {
      this.currentFilter = filterData
      this.selectorDialogModel.data = this.currentFilter.data
      /*
        Si no hay datos en la prop selectorDialogItems, lo cual significaría la primer apertura del diálogo
        para ese filtro, se verifica que no haya datos en la prop selectedData (si se configuraron unos datos
        seleccionados por defecto), o sino, se setea en null.
      */
      this.selectorDialogModel.selected = filterData.selectedData.length > 0 && this.currentFilter.selectorDialogItems
        ? this.currentFilter.selectorDialogItems : (filterData.selectedData.length > 0
          ? this.getIds(filterData.selectedData, filterData.singleSelect) : null)
      this.selectorDialogModel.title = this.$t('selector.selectionDialog.title', { entityName: filterData.name })
      this.selectorDialogModel.singleSelect = filterData.singleSelect
      this.selectorDialogModel.selectAll = false
      this.selectorDialogModel.show = true
    },
    cancelDialogAction() {
      this.selectorDialogModel.show = false
    },
    getGreaterThanRules(model) {
      const rules = [];
      if (model.rangeData.limitRequired) {
        rules.push(model.rangeData.rules.limitMinReached(model.rangeData.greaterThan, model.rangeData.minLimit))
        rules.push(model.rangeData.rules.limitMaxReached(model.rangeData.greaterThan, model.rangeData.maxLimit))
      } else if (model.rangeData.lessThan !== '') {
        rules.push(model.rangeData.rules.limitMaxReached(model.rangeData.greaterThan, model.rangeData.lessThan))
      }
      return rules;
    },
    getLessThanRules(model) {
      const rules = [];
      if (model.rangeData.limitRequired) {
        rules.push(model.rangeData.rules.limitMinReached(model.rangeData.lessThan, model.rangeData.minLimit))
        rules.push(model.rangeData.rules.limitMaxReached(model.rangeData.lessThan, model.rangeData.maxLimit))
      } else if (model.rangeData.greaterThan !== '') {
        rules.push(model.rangeData.rules.limitMinReached(model.rangeData.lessThan, model.rangeData.greaterThan))
      }
      return rules;
    },
    /**
     * TO DO: Implementar recursivamente (para n niveles)
     */
    saveDialogAction(data) {
      let count = 0;
      this.currentFilter.selectorDialogItems = deepcopy(data)
      if (data) {
        const selectedItems = []
        if (this.currentFilter.singleSelect && data) {
          selectedItems.push(data)
        }
        const itemsCopy = deepcopy(this.currentFilter.data)
        const filteredData = itemsCopy.filter(function filterFunction(item) {
          if (data.includes(item.id)) {
            if (item.children) count += item.children.length
            return true
          }
          if (item.children && item.children.length > 0) {
            const filtered = (item.children = item.children.filter(filterFunction)).length
            count += filtered;
            return filtered
          }
        })

        selectedItems.cleanAndUpdate(filteredData)
        this.currentFilter.selectedData.cleanAndUpdate(selectedItems)
      } else {
        this.currentFilter.selectedData.clean()
      }
      if (this.currentFilter.selectAction) this.currentFilter.selectAction()
      this.selectorDialogModel.show = false;
      this.selectedCount = count;
      if (this.model.showCount && this.model.selectionLimit) {
        this.model.disableGetReportButton = !count || count > this.model.selectionLimit;
      }
    },
    /**
     * Click en el botón para generar el reporte
     */
    getReportClick() {
      if (this.autoReportNameEnabled && this.model.autoReport) {
        this.showAutoreportNameDialog = true
      } else {
        this.emitGetReport()
      }
    },
    /**
     * Emitir un evento al componente padre con los datos del selector para generar el reporte
     */
    emitGetReport() {
      const emitEvent = (warningAccepted) => {
        this.$emit('get-report', {
          filters: this.model.filters,
          rangeData: this.model.rangeData,
          selects: this.model.selects,
          dateAndTimeRange: this.model.showDateAndTimeSelector ? this.model.selectedDateAndTimeRange : null,
          dateAndTimeRangeCustomType: this.model.selectedDateAndTimeRangeCustomType ? this.model.selectedDateAndTimeRangeCustomType : null,
          sinceDate: this.model.showDateAndTimeSelector ? this.model.sinceDate : null,
          sinceTime: this.model.showDateAndTimeSelector ? this.model.sinceTime : null,
          toDate: this.model.showDateAndTimeSelector ? this.model.toDate : null,
          toTime: this.model.showDateAndTimeSelector ? this.model.toTime : null,
          autoReport: this.model.autoReport,
          autoReportMinutes: this.model.autoReportMinutes,
          dateAndTimeFormat: this.model.showTimeFormatSelector ? this.model.selectedTimeFormat : null,
          autoReportName: this.model.autoReportName,
          filterSwitchValue: this.model.filterSwitch != null ? this.model.filterSwitch.value : false,
          numericInputs: this.model.numericInputs,
          groupEquipmentFilterSelected: this.model.groupEquipmentFilter != null
            ? this.model.groupEquipmentFilter.selectedData
            : [],
          warningAccepted
        })
      }
      if (this.showWarning && (this.model.selectedDateAndTimeRange === TimeRanges.CUSTOM || this.model.selectedDateAndTimeRange === TimeRanges.HARVEST_START)) {
        let openWarningDialog = true
        if (this.model.selectedDateAndTimeRange === TimeRanges.CUSTOM) {
          let tTo
          let tFrom
          switch (this.model.selectedDateAndTimeRangeCustomType) {
            case CustomTimeRangeTypes.DATE_AND_TIME:
              // date and time
              tTo = moment(this.model.toDate + ' ' + this.model.toTime, 'YYYY-MM-DD hh:mm')
              tFrom = moment(this.model.sinceDate + ' ' + this.model.sinceTime, 'YYYY-MM-DD hh:mm')
              break
            case CustomTimeRangeTypes.DATE:
              // only date
              tTo = moment(this.model.toDate, 'YYYY-MM-DD')
              tFrom = moment(this.model.sinceDate, 'YYYY-MM-DD')
              break
          }
          const ms = tTo.diff(tFrom)
          const duration = moment.duration(ms).asHours()
          openWarningDialog = duration > 48
        }
        if (openWarningDialog) {
          this.openDialog({
            title: this.$t('selector.warning'),
            text: this.warningText,
            type: DialogType.WARNING,
            overlayOpacity: 0.5,
            actionButtons: true,
            yesAction: async () => {
              emitEvent(true)
            },
            noAction: () => { }
          })
        } else {
          emitEvent(false)
        }
      } else {
        emitEvent(false)
      }
    },
    sortData(select) {
      select.data.sort(function sortFunction(a, b) {
        const nameA = a[select.text].toLowerCase()
        const nameB = b[select.text].toLowerCase()
        if (nameA < nameB) {
          return -1
        } else if (nameA > nameB) {
          return 1
        } else {
          return 0
        }
      })
    },
    close() {
      this.$emit('close')
    },
    /**
     * Validación de nombre duplicado del auto-reporte
     */
    async duplicatedReportNameValidation() {
      const exists = this.model.autoReportName != null && this.model.autoReportName !== ''
        ? autoReportService.validateAutoReportName(this.model.autoReportName)
        : false
      if (exists) {
        this.reportNameErrors.push(this.$t('selector.duplicatedNameError'))
      } else {
        this.reportNameErrors = []
      }
    },
    /**
     * Cancelar el dialog para ingresar el nombre del autoreporte
     */
    autoReportNameCancel() {
      if (this.$refs.autoReportNameForm) {
        this.$refs.autoReportNameForm.reset()
      }
      this.showAutoreportNameDialog = false
      this.reportNameErrors = []
    },
    /**
     * Guardar el dialog para ingresar el nombre del autoreporte
     */
    autoReportNameSave() {
      this.emitGetReport()
      if (this.$refs.autoReportNameForm) {
        this.$refs.autoReportNameForm.reset()
      }
      this.showAutoreportNameDialog = false
      this.reportNameErrors = []
    },
    selectedFunction(newValue, select) {
      select.selectedData = newValue && newValue[select.value] ? newValue[select.value] : newValue
    },
    autoReportSwitchChanged(value) {
      if (this.model.disableDateAutoReport) {
        this.autoReportDisableDate = value;
      }
    }
  },
  watch: {
    'model.selectedDateAndTimeRange': function (val) {
      if (this.model.autoReport && (val === TimeRanges.YESTERDAY || val === TimeRanges.CUSTOM)) {
        this.model.autoReport = false
      }
    },
    'model.addActiveHarvestDate': function (val) {
      if (val) {
        this.dateAndTimeRanges.splice(7, 0, {
          id: TimeRanges.HARVEST_START,
          text: i18n.t('selector.dateAndTime.activeHarvest'),
          custom: false
        })
      } else {
        this.dateAndTimeRanges.splice(7, 1)
      }
    }
  }
}
