/* eslint-disable indent */
import enterpriseApi from '@/api/enterprise.api'
import thingApi from '@/api/thing.api'
import selectorModel from '@/components/commons/selector/model'
import { clone } from 'ramda'

/**
 * Obtener el tiempo de jornada diaria de la empresa
 * @returns
 */
const getEnterpriseCustomTimeSpan = async () => {
    try {
        const response = await enterpriseApi.getEnterpriseCustomTimeSpan()
        return response.data
    } catch (e) {
        console.error(e)
        return null
    }
}

/**
 * Obtener el árbol de empresa -> grupo -> equipo para el selector
 * @param {*} sector
 */
const getEnterpriseGroupEquipmentTreeSelector = async (sector = null) => {
    try {
        const response = await thingApi.getNested(sector)
        return response.data
    } catch (e) {
        console.error(e)
        return []
    }
}

/**
 * Obtener el modelo para el selector
 * @returns
 */
const getSelectorModel = (showGroupEquipmentFilter = false) => {
    const model = clone(selectorModel)
    model.showGroupEquipmentFilter = showGroupEquipmentFilter
    model.filterSwitch.value = showGroupEquipmentFilter
    return model
}

export default {
    getEnterpriseCustomTimeSpan,
    getEnterpriseGroupEquipmentTreeSelector,
    getSelectorModel
}
